import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useIntl } from "gatsby-plugin-intl"
import { navigate, Link } from "gatsby"

const BioCard = props => {
  const intl = useIntl()

  function navigateTo(url) {
    let path = (props.category != '') ? props.category + url : url.substring(1, url.length);
    navigate( path);
  }

  return (
    <Card sx={{ maxWidth: 345 }} style={{marginBottom: '2em'}}>
      <CardMedia
        component="img"
        height="240"
        image={props.postinfo.frontmatter.thumbnail.publicURL}
        alt="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {props.postinfo.frontmatter.title}
        </Typography>
        <Typography variant="body2">
          {props.postinfo.frontmatter.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="outlined" size="small" onClick={() => {navigateTo( props.postinfo.frontmatter.slug);}}>{intl.formatMessage({ id: "more" })}</Button>
      </CardActions>
    </Card>
  );
}

export default BioCard
